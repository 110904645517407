import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as crypto from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
      this.status.next(value);
  }

  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;

  encryptUsingAES256(id:any) 
  {
  
    let _key = crypto.enc.Utf8.parse(this.tokenFromUI);
    let _iv = crypto.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = crypto.AES.encrypt(
      JSON.stringify(id), _key, {
        keySize: 16,
        iv: _iv,
        mode: crypto.mode.ECB,
        padding: crypto.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return this.encrypted;
    
  }
  decryptUsingAES256(id:any) 
  {
   
    let _key = crypto.enc.Utf8.parse(this.tokenFromUI);
    let _iv = crypto.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = crypto.AES.decrypt(
      id, _key, {
        keySize: 16,
        iv: _iv,
        mode: crypto.mode.ECB,
        padding: crypto.pad.Pkcs7
      }).toString(crypto.enc.Utf8);

      return this.decrypted;
     
  }

}
