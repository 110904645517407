import { Injectable } from '@angular/core';
declare var createAnalytics: (analyticsTrackingId: string) => void;
@Injectable({
  providedIn: 'root'
})

export class AnalyticsServiceService {

  init() {
    const analyticsTrackingId = "UA-260826018-1";
    createAnalytics(analyticsTrackingId);
  }
}
