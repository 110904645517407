import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {

  private key = environment.encryptionKey;
  private iv = environment.iv;
  private token = environment.token;
  constructor() {}

  encryptToken(): string {
    const timestamp = Math.floor(Date.now() / 1000);
    let dataToEncrypt = `${this.token.trim()}|${timestamp.toString()}`;
    const key = CryptoJS.enc.Utf8.parse(this.key);
    const iv = CryptoJS.enc.Utf8.parse(this.iv);

    const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

}
